$theme-colors: (
  "primary": #ff6b00,
  "secondary": #8f9fa0,
  "dark": #434342,
  "light": #d9e0e2
);

@import "ember-bootstrap/bootstrap";

.btn-secondary {
  color: #fff;
}

.spinner-lg {
  margin-top: 2rem;
  height: 3.75rem;
  width: 3.75rem;
  background-image: url('/assets/logo.png');
}

.notify {
  bottom: unset !important;
  top: 0;
}

.pointer {
  cursor: pointer;
}

// Navbar
.nav-link.log-out {
  padding-left: 0;
}

// Notes should support new lines
samp {
  white-space: pre-line;
}

// Override disabled `flatpickr` input
.ember-flatpickr-input ~ input.form-control,
.flatpickr-input.form-control {
  background-color: transparent;
}

// Map component
.map {
  min-height: 15rem;
}

// FileManager
.file-upload-drag {
  background-color: #f8f8ff;
}

.disable-event {
  pointer-events: none;
}

// Dashed line
hr.dashed {
  border-top-style: dashed;
}

// Scheduler
.event {
  font-size: 70%;
  display: flow-root;
}

.k-icon.k-i-arrow-60-left:before {
  content: "←";
}

.k-icon.k-i-arrow-60-right:before {
  content: "→";
}

.k-icon.k-i-calendar:before {
  content: "🗓️";
  font-size: 75%;
}

.k-icon.k-i-reload {
  display: none;
}

.k-event.k-event-inverse {
  border-style: none;
}

.k-today {
  cursor: pointer;
}

.k-today:hover::before {
  content: '+';
  font-size: 250%;
  position: absolute;
  margin-top: -0.5rem;
}

// Split
.grid {
  height: 85vh;
  padding: 0;
  display: grid;
  grid-template-rows: 1fr 10px 1fr;
}

.horizontal-gutter {
  background-color: rgba(0, 0, 0, .3);
  background-image: linear-gradient(0, rgba(255, 255, 255, .5), #fff);
  cursor: ns-resize;
  grid-area: 2 / 1 / 2 / 4;
}

.map-split {
  grid-area: 1 / 1 / 2 / 4;
}

.scheduler-split {
  grid-area: 3 / 1 / 4 / 4;
}

.k-scheduler {
  overflow-y: auto;
  max-height: 100%;
}

.jobs-list {
  overflow: auto;
  max-height: 85vh;
}

// Scanner
.scanner > canvas {
  display: none;
}

.scanner > video {
  max-height: 70%;
  max-width: 100%;
}

// Modal
.left-buttons {
  left: 1rem;
  position: absolute;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 525px;
  }
}

// Signature Pad
canvas.signature {
  background-color: #F8F8FF;
  height: 30vh;
  width: 100%;
}

// Service Performed
.w-100-button > h5 > button {
  width: 100%;
}

.w-100-button .row {
  margin-left: -30px;
  margin-right: -30px;
}

// ember-select
.es-input {
  height: 24px;
}
